import { useCarStore } from "@/_store/use-car-store"
import { HttpClient } from "@/_utils/axios"
import axios from "axios"
import { ReactNode, useEffect, useState } from "react"
import { AiOutlineRight } from "react-icons/ai"
import { Link } from "react-router-dom"
import "./SelectCar.scss"



function Detail({ title, className, children }: { title: string, className?: string, children?: ReactNode }) {
    return <div className={`mt-3 flex-1 ${className} flex flex-col overflow-hidden`}>
        <div className="bg-[#EFF0F1] w-full h-9 flex flex-row text-sm px-3 justify-center items-center space-x-2 mb-3 text-gray-700">
            <p className="font-semibold">{title}</p>
            <div className="flex-1"></div>
        </div>
        <div className="overflow-y-scroll overflow-x-hidden h-full">
            {children}
        </div>
    </div>
}

function SelectCarModel() {
    const [models, setModels] = useState<Array<CarModel>>()
    const carStore = useCarStore()

    const handleCarModelClick = (modelId: string) => {
        carStore.setCarModel(modelId)
        carStore.setCarYear("")
        carStore.setCarOrder("")
        carStore.setModelNr("")
    }

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'api/porsche/model-types',
            headers: {}
        };
        HttpClient.request(config)
            .then((response) => {
                setModels(response.data as any);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [carStore])

    return (
        <Detail title="车型系列" className="mb-9">
            {models &&
                models.map((item) => (
                    <div
                        onClick={() => { handleCarModelClick(item.modelTypeId) }}
                        className={
                            (carStore.carModel == item.modelTypeId ? "bg-[#15577E] text-white " : "") +
                            "flex flex-row border-b-2 px-3 py-1 text-sm justify-center items-center cursor-pointer"
                        }
                        style={{ userSelect: "none" }}
                    >
                        <p className="flex-1">{item.modelNameEN}</p>
                        <div className="text-gray-500">
                            <AiOutlineRight size={21} />
                        </div>
                    </div>
                ))
            }
        </Detail >
    )
}

function SelectCarYear() {
    const [years, setYears] = useState<Array<CarYear>>()
    const carStore = useCarStore()

    const handleCarYearClick = (year: string) => {
        carStore.setCarYear(year)
        carStore.setCarOrder("")
        carStore.setModelNr("")
    }

    useEffect(() => {
        if (carStore.carModel == "") return;
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/model-years?modelTypeId=${carStore.carModel}`,
            headers: {}
        };
        HttpClient.request(config)
            .then((response) => {
                setYears(response.data as any);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [carStore.carModel])

    return (
        <Detail title="年款" className="h-2/5">
            {years &&
                years.map((item) => (
                    <div
                        onClick={() => { handleCarYearClick(item.itemEn) }}
                        className={
                            (item.itemEn == carStore.carYear ? "bg-[#15577E] text-white " : "") +
                            "flex flex-row border-b-2 px-3 py-1 text-sm justify-center items-center cursor-pointer"
                        }
                        style={{ userSelect: "none" }}
                    >
                        <p className="flex-1">{item.itemEn}</p>
                        <div className="text-gray-500">
                            <AiOutlineRight size={21} />
                        </div>
                    </div>
                ))
            }
        </Detail>
    )
}

function SelectCarOrder() {
    const [orders, setOrders] = useState<Array<CarYear>>()
    const carStore = useCarStore()

    const handleCarOrderClick = (order: string) => {
        carStore.setCarOrder(order)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/model-nr?year=${carStore.carYear}&modelTypeId=${carStore.carModel}&order=${order}`,
        };
        HttpClient.request(config)
            .then((response) => {
                console.log(response.data)
                carStore.setModelNr(response.data as any);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (carStore.carYear == "") return;
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/model-list?year=${carStore.carYear}&modelTypeId=${carStore.carModel}`,
            headers: {}
        };
        HttpClient.request(config)
            .then((response) => {
                setOrders(response.data as any);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [carStore.carYear])

    return (
        <div className="flex-1 flex flex-col h-5/6">
            <Detail title="订单数据">
                {orders &&
                    orders.map((item) => (
                        <div
                            onClick={() => { handleCarOrderClick(item.itemEn) }}
                            className={
                                (item.itemEn == carStore.carOrder ? "bg-[#15577E] text-white " : "") +
                                "flex flex-row border-b-2 px-3 py-1 text-sm justify-center items-center cursor-pointer"
                            }
                            style={{ userSelect: "none" }}
                        >
                            <p className="flex-1">{item.itemEn}</p>
                            <div className="text-gray-500">
                                <AiOutlineRight size={21} />
                            </div>
                        </div>
                    ))
                }
            </Detail>
            <Link to="/media-info">
                <button className="flex flex-row justify-center items-center bg-red-600 px-4 py-2 space-x-2 text-white text-sm mt-9 place-self-end">
                    {">"}
                    继续处理此车辆
                </button>
            </Link>
        </div>
    )
}

export default function SelectCar() {


    return (
        <div className="flex flex-row h-full space-x-6">
            <SelectCarModel />
            <SelectCarYear />
            <SelectCarOrder />
        </div>
    )
}
