import { ReactNode } from "react"

function Detail({ title, className, children }: { title: string, className?: string, children?: ReactNode }) {
    return (
        <div className={`mt-3 flex-1 ${className} flex flex-col overflow-hidden`}>
            <div className="border-b-2 w-full h-9 flex flex-row text-sm px-3 justify-center items-center space-x-2 mb-3 text-gray-700">
                <p className="font-semibold">{title}</p>
                <div className="flex-1"></div>
            </div>
            <div className="overflow-x-hidden h-full p-3 space-y-3">
                {children}
            </div>
        </div>
    )
}

export default function CarMediaAll() {

    return (
        <div className="flex flex-row h-full space-x-6">
            <Detail title="零件ID特定媒体">
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        MC
                    </div>
                    <p className="flex justify-center items-center ">出场证明</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        SIT
                    </div>
                    <p className="flex justify-center items-center ">技术服务信息</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        SY
                    </div>
                    <p className="flex justify-center items-center ">基于症状的车间手册</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        TEQ
                    </div>
                    <p className="flex justify-center items-center ">精装配件</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        TI
                    </div>
                    <p className="flex justify-center items-center ">技术信息</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        WM
                    </div>
                    <p className="flex justify-center items-center ">车间手册</p>
                </div>
            </Detail>
            <Detail title="非零件ID特定媒体">
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#AFB1B2] w-16 flex justify-center items-center text-white text-xs">
                        CAM
                    </div>
                    <p className="flex justify-center items-center ">活动</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        DIAG
                    </div>
                    <p className="flex justify-center items-center ">诊断信息</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        EM
                    </div>
                    <p className="flex justify-center items-center ">环保信息</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        ETM
                    </div>
                    <p className="flex justify-center items-center ">排放测试</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        OWM
                    </div>
                    <p className="flex justify-center items-center ">车主手册</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        PM
                    </div>
                    <p className="flex justify-center items-center ">喷漆手册</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        SF
                    </div>
                    <p className="flex justify-center items-center ">标准表格</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        SY
                    </div>
                    <p className="flex justify-center items-center ">基于症状的车间手册</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        WD
                    </div>
                    <p className="flex justify-center items-center ">电路图</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        WEST
                    </div>
                    <p className="flex justify-center items-center ">车间设备</p>
                </div>
                <div className="w-full h-9 flex flex-row space-x-3 text-sm">
                    <div className="bg-[#494E51] w-16 flex justify-center items-center text-white text-xs">
                        电视
                    </div>
                    <p className="flex justify-center items-center ">技术值</p>
                </div>
            </Detail>
        </div>
    )
}
