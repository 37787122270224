import { BsExclamationOctagonFill, BsExclamationTriangle } from "react-icons/bs"

const StyleSheet: Styles = {
    root: { "display": "flex", "borderTopWidth": "2px", "height": "3rem" },
    nav_bar: { "display": "flex", "flex": "1 1 0%" },
    l_icon: { "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100%", "color": "#ffffff", "backgroundColor": "#DC2626", "width": "48px" },
    l_icon_2: { "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100%", "color": "#ffffff", "backgroundColor": "#F59E0B", "width": "48px" },
    current_task: { "display": "flex", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "flexDirection": "column", "flex": "1 1 0%", "justifyContent": "center", "fontSize": "0.875rem", "lineHeight": "1.25rem", "color": "#4B5563" },
    r_menu: { "display": "flex", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "marginLeft": "0.25rem", "flexDirection": "row", "alignItems": "center", "height": "100%", "color": "#ffffff", "width": "270px", "background": "#2A567B" },
}

export default function Footer() {
    return (
        <div style={StyleSheet.root}>
            <div style={StyleSheet.nav_bar}>
                <div style={StyleSheet.l_icon}>
                    <BsExclamationOctagonFill />
                </div>
                <div style={StyleSheet.l_icon_2}>
                    <BsExclamationTriangle />
                </div>
                <div style={StyleSheet.current_task}>
                    <p>WP1AE2926GLA17088,Cayenne S E-Hybrid (92AAE1]2016</p>
                    <p>标准作业</p>
                </div>
            </div>
            <div style={StyleSheet.r_menu}>
            </div>
        </div>
    )
}