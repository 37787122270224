"use client"
import "./style.scss"
import { useCarStore } from '@/_store/use-car-store';
import { useEffect, useState } from 'react';
import { HttpClient } from '@/_utils/axios';
import { useUserStore } from '@/_store/use-user-store';
import { useNavigate, useSearchParams } from 'react-router-dom';

//todo fix build error

// export default function InstructionDoc({ docHref }: { docHref?: string }) {
export default function InstructionDoc({ docHref }: any) {
    const [markdown, setMarkdown] = useState<string>()
    const [searchParams, setSearchParams] = useSearchParams()
    const carStore = useCarStore()

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/repair-instruction-detail?docuHref=${docHref ? docHref : searchParams.get("docuHref")}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                setMarkdown(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }, [carStore])

    useEffect(() => {
        console.log("dedesd")
        document.getElementsByClassName("markdown")[0].innerHTML = marked.parse(
            document.getElementsByClassName("markdown")[0].innerHTML
        );
    }, [markdown])

    return (
        <div className="h-full overflow-scroll w-screen overflow-x-hidden">
            <div className="markdown">{markdown}</div>
        </div>
    )
}