import { useUserStore } from '@/_store/use-user-store';
import { Navigate, Outlet } from 'react-router-dom'
const PrivateRoutes = () => {
  let auth = false
  const jwt = useUserStore((state) => state.jwt);
  const username = useUserStore((state) => state.username);
  if (jwt != "" && username != "") {
    auth = true
  }
  return (
    auth ? <Outlet /> : <Navigate to='/login' />
  )
}
export default PrivateRoutes