"use client"
import "./media-info.scss"
import { useState } from "react";
import Footer from "../_components/Footer";
import SideBar from "../_components/SideBar";
import CarComponentMedia from "./_component/CarComponentMedia";
import CarMediaAll from "./_component/CarMediaAll";
import Empty from "./_component/Empty";

function TabItem({ text, className, handleClick }: { text: string, className?: string, handleClick?: () => void }) {
    return (
        <div onClick={handleClick} className={`py-1 pr-6 pl-3 border-b-2 text-sm ${className}`}>{text}</div>
    )
}

export default function CarSearch() {
    const [selectedTab, setSelectedTab] = useState(0)

    const handleTabClick = (value: number) => {
        setSelectedTab(value)
    }

    return (
        <div className="flex flex-col h-full overflow-hidden">
            <div className="flex flex-row h-full flex-1 overflow-hidden">
                <div className="flex-1 p-3">
                    <div className="flex flex-row text-gray-600 space-x-3" >
                        <TabItem text="零件特定媒体" className={"cursor-pointer " + (selectedTab == 0 && "border-red-600")} handleClick={() => { handleTabClick(0) }} />
                        <TabItem text="所有媒体" className={"cursor-pointer " + (selectedTab == 1 && "border-red-600")} handleClick={() => { handleTabClick(1) }} />
                        <TabItem text="人工操作" className={"cursor-pointer " + (selectedTab == 2 && "border-red-600")} handleClick={() => { handleTabClick(2) }} />
                        <TabItem text="零件" className={"cursor-pointer " + (selectedTab == 3 && "border-red-600")} handleClick={() => { handleTabClick(3) }} />
                        <TabItem text="增强的媒体搜索" className={"cursor-pointer " + (selectedTab == 4 && "border-red-600")} handleClick={() => { handleTabClick(4) }} />
                        <TabItem text="书签" className={"cursor-pointer " + (selectedTab == 5 && "border-red-600")} handleClick={() => { handleTabClick(5) }} />
                        <TabItem text="" className="flex-1" />
                    </div>
                    <div className="p-6 h-full overflow-scroll">
                        {selectedTab == 0 && <CarComponentMedia />}
                        {selectedTab == 1 && <CarMediaAll />}
                        {selectedTab == 2 && <Empty />}
                        {selectedTab == 3 && <Empty />}
                        {selectedTab == 4 && <Empty />}
                        {selectedTab == 5 && <Empty />}
                    </div>
                </div>
                <SideBar />
            </div>
            <Footer />
        </div>
    )
}