import stylesheet from "./car-search.module.scss"
import { useState } from "react";
import Footer from "../_components/Footer";
import SideBar from "../_components/SideBar";
import SearchVin from "./_component/SearchVin";
import SelectCar from "./_component/SelectCar";
import ElectricCar from "./_component/ElectricCar";

function TabItem({ text, className, handleClick }: { text: string, className?: string, handleClick?: () => void }) {
    return (
        <div onClick={handleClick} className={`${stylesheet.btn} ${className}`}>{text}</div>
    )
}

export default function CarSearch() {
    const [selectedTab, setSelectedTab] = useState(1)

    const handleTabClick = (value: number) => {
        setSelectedTab(value)
    }

    return (
        <div className={stylesheet.car_search}>
            <div className={stylesheet.nav}>
                <div className={stylesheet.content}>
                    <div className={stylesheet.content_nav_bar} >
                        <TabItem text="通过VIN识别" className={`${stylesheet.mouseover} ${(selectedTab == 0 && stylesheet.tab_item)}`} handleClick={() => { handleTabClick(0) }} />
                        <TabItem text="通过车辆识别" className={`${stylesheet.mouseover} ${(selectedTab == 1 && stylesheet.tab_item)}`} handleClick={() => { handleTabClick(1) }} />
                        <TabItem text="充电产品" className={`${stylesheet.mouseover} ${(selectedTab == 2 && stylesheet.tab_item)}`} handleClick={() => { handleTabClick(2) }} />
                        <TabItem text="" className={stylesheet.space} />
                    </div>
                    <div className={stylesheet.item}>
                        {selectedTab == 0 && <SearchVin />}
                        {selectedTab == 1 && <SelectCar />}
                        {selectedTab == 2 && <ElectricCar />}
                    </div>
                </div>
                <SideBar />
            </div>
            <Footer />
        </div>
    )
}