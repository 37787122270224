import { AiOutlineRightCircle, AiOutlineQuestionCircle } from "react-icons/ai"
import { FaUserCircle } from "react-icons/fa"
import stylesheet from "./SideBar.module.scss"

export default function SideBar() {
    return (
        <div className={stylesheet.root}>
            <AiOutlineRightCircle size={27} />
            <AiOutlineQuestionCircle size={27} />
            <FaUserCircle size={27} />
        </div>
    );
};