import { AiOutlineUp, AiOutlineSearch, AiOutlineClose, AiOutlineArrowRight, AiFillExclamationCircle } from "react-icons/ai"
import { BsExclamationOctagonFill } from "react-icons/bs"
import NoDataPage from "@/_components/NoDataPage"
import stylesheet from "./SearchVin.module.scss"

function Detail({ title, notifyCount }: { title: string, notifyCount?: string }) {
    return <div className={stylesheet.detail}>
        <div className={stylesheet.detail_nav}>
            <p>{title}</p>
            <div className={stylesheet.detail_nav_icon_box}>
                {notifyCount && <div className={stylesheet.detail_nav_notify}>
                    {notifyCount}
                </div>}
            </div>
            <AiOutlineUp size={15} />
        </div>
        <div className={stylesheet.detail_content}>
            <NoDataPage />
        </div>
    </div>
}

export default function SearchVin() {
    return (
        <>
            <div className={stylesheet.search_box}>
                <AiOutlineSearch size={21} className={stylesheet.search_icon} />
                <input type="text" className={stylesheet.search_input} placeholder="输入车架号码" />
                <AiOutlineClose size={21} />
                <div className={stylesheet.search_icon_2}>
                    <AiOutlineArrowRight size={24} />
                </div>
            </div>
            <div className={stylesheet.content}>
                <div className={stylesheet.car_image}>
                    {/* <Image src="/image/car_empty.png" fill className=" object-contain" alt="car" /> */}
                </div>
                <div className={stylesheet.car_info}>
                    <div className={stylesheet.car_info_text_box}>
                        <p className={stylesheet.car_info_text_box_title}>车辆识别号(VIN)</p>
                        <p>WP1AE2926GLA17088</p>
                    </div>
                    <div className={stylesheet.car_info_text_box}>
                        <p className={stylesheet.car_info_text_box_title}>订单类型</p>
                        <p>Cayenne S E-Hybrid (92AAE 1)</p>
                    </div>
                    <div className={stylesheet.car_info_text_box}>
                        <p className={stylesheet.car_info_text_box_title}>年款</p>
                        <p>2016</p>
                    </div>
                    <div className={`${stylesheet.car_info_card}`} style={{ color: "#DC2626" }}>
                        <div className={`${stylesheet.car_info_card_icon}`} style={{ "backgroundColor": "#DC2626" }}>
                            <BsExclamationOctagonFill size={18} />
                        </div>
                        <p>有针对此车辆的未完成活动。</p>
                    </div>
                    <div className={`${stylesheet.car_info_card}`} style={{ color: "#B0B1B2" }}>
                        <div className={`${stylesheet.car_info_card_icon}`} style={{ "background": "#B0B1B2" }}>
                            <AiFillExclamationCircle size={21} />
                        </div>
                        <p>San Bao 车辆</p>
                    </div>
                </div>
            </div>
            <Detail title="活动" notifyCount="1" />
            <Detail title="保修数据" />
            <div className={stylesheet.detail_box}>
                <Detail title="车辆说明和一般信息" />
                <Detail title="车辆设备（M编号）" />
            </div>
        </>
    )
}