"use client"
import "./style.scss"
import { useEffect, useState } from 'react';
import { HttpClient } from '@/_utils/axios';
import { useUserStore } from '@/_store/use-user-store';
import { useNavigate, useSearchParams } from 'react-router-dom';

//todo fix build error

// export default function LaborDoc({ contentId, modelNr, hgrpCode }: { contentId?: string, modelNr: string, hgrpCode: string }) {
export default function LaborDoc({ contentId, modelNr, hgrpCode }: any) {
    const [labor, setLabor] = useState<laborResp>()
    const [searchParams, setSearchParams] = useSearchParams()
    const mContentId = contentId ? contentId : searchParams.get("contentId")
    const mModelNr = modelNr ? modelNr : searchParams.get("modelNr")
    const mHgrpCode = hgrpCode ? hgrpCode : searchParams.get("hgrpCode")

    const userStore = useUserStore()
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Labor"
    }, [])

    useEffect(() => {
        if (useUserStore.getState().jwt == "" || useUserStore.getState().username == "") {
            navigate("/login", { replace: true });
        }
    }, [userStore, navigate]);
    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/labor-detail?modelNr=${mModelNr}&contentId=${mContentId}&hgrpCode=${mHgrpCode}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                console.log(response.data[0])
                setLabor(response.data[0])
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])

    return (
        labor ?
            <div className="h-full overflow-scroll font-bold p-3 space-y-2">
                <div className='text-blue-500'>{labor.laborTitle}</div>
                <div>
                    工时数:{labor.laborTime} {labor.timeSumDesc}
                </div>
                <div className='text-[#A5A5A5]'>
                    <div>
                        工时单价:¥{labor.timeFeee && (labor.timeFeee / 100).toFixed(2)}({labor.priceDesc})
                    </div>
                    <div>
                        工时总价:¥{labor.laborFeee && (labor.laborFeee / 100).toFixed(2)}
                    </div>
                </div>
                <div className='rounded-md p-2 bg-blue-200 w-64 text-sm'>
                    {
                        labor.commonText.length != 0 &&
                        <div>
                            <div className='text-white bg-blue-500 w-fit p-1 rounded-md'>
                                <p>一般信息: </p>
                            </div>
                            {
                                labor!.commonText.map((item: string) => {
                                    return <p>{item}</p>
                                })
                            }
                        </div>
                    }
                    {
                        labor.preText.length != 0 &&
                        <div>
                            <div className='text-white bg-blue-500 w-fit p-1 rounded-md'>
                                <p>前提: </p>
                            </div>
                            {
                                labor!.preText.map((item: string) => {
                                    return <p>{item}</p>
                                })
                            }
                        </div>

                    }
                    {
                        labor.includeText.length != 0 &&
                        <div>
                            <div className='text-white bg-blue-500 w-fit p-1 rounded-md'>
                                <p>包括: </p>
                            </div>
                            {
                                labor!.includeText.map((item: string) => {
                                    return <p>{item}</p>
                                })
                            }
                        </div>
                    }
                    {
                        labor.withoutText.length != 0 &&
                        <div>
                            <div className='text-white bg-blue-500 w-fit p-1 rounded-md'>
                                <p>不包括: </p>
                            </div>
                            {
                                labor!.withoutText.map((item: string) => {
                                    return <p>{item}</p>
                                })
                            }
                        </div>
                    }
                </div>
            </div> : <></>
    )
}