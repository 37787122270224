// todo store
import { create } from "zustand";

type CarStore = {
  carModel: string;
  carYear: string;
  carOrder: string;
  modelNr: string;
  diagnosisDocuHref: string;
  diagnosisDocuTitle: string;
  diagnosisType?: string;
  repairInstructionDocuHref: string;
  repairInstructionDocuTitle: string;
  repairInstructionType?: string;
  laborContentId: string;
  laborHgrpCode: string;
  laborTitle: string;
  setCarModel: (model: string) => void;
  setCarYear: (year: string) => void;
  setCarOrder: (order: string) => void;
  setModelNr: (nr: string) => void;
  setDiagnosisDocuHref: (docuHref: string) => void;
  setDiagnosisType: (data: string | undefined) => void;
  setDiagnosisDocuTitle: (title: string) => void;
  setRepairInstructionDocuHref: (docuHref: string) => void;
  setRepairInstructionType: (data: string | undefined) => void;
  setRepairInstructionDocuTitle: (title: string) => void;
  setLaborContentId: (contentId: string) => void;
  setLaborHgrpCode: (hgrpCode: string) => void;
  setLaborTitle: (title: string) => void;
};

export const useCarStore = create<CarStore>()((set) => ({
  carModel: "",
  carYear: "",
  carOrder: "",
  modelNr: "",
  diagnosisDocuHref: "",
  diagnosisDocuTitle: "",
  diagnosisType: undefined,
  repairInstructionDocuHref: "",
  repairInstructionDocuTitle: "",
  repairInstructionType: undefined,
  laborContentId: "",
  laborHgrpCode: "",
  laborTitle: "",
  setCarModel: (model) => set({ carModel: model }),
  setCarYear: (year) => set({ carYear: year }),
  setCarOrder: (order) => set({ carOrder: order }),
  setModelNr: (nr) => set({ modelNr: nr }),
  setDiagnosisDocuHref: (docuHref) => set({ diagnosisDocuHref: docuHref }),
  setDiagnosisType: (data) => set({ diagnosisType: data }),
  setDiagnosisDocuTitle: (title) => set({ diagnosisDocuTitle: title }),
  setRepairInstructionDocuHref: (docuHref: string) =>
    set({
      repairInstructionDocuHref: docuHref,
    }),
  setRepairInstructionType: (data: string | undefined) =>
    set({
      repairInstructionType: data,
    }),
  setRepairInstructionDocuTitle: (title: string) =>
    set({
      repairInstructionDocuTitle: title,
    }),
  setLaborContentId: (contentId: string) =>
    set({
      laborContentId: contentId,
    }),
  setLaborHgrpCode: (hgroCode: string) =>
    set({
      laborHgrpCode: hgroCode,
    }),
  setLaborTitle: (laborTitle: string) => {
    set({
      laborTitle: laborTitle,
    });
  },
}));
