import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { HttpClient } from '@/_utils/axios';
import { useUserStore } from '@/_store/use-user-store';

function Login() {
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const userStore = useUserStore();

  const onSubmit = () => {
    // todo need more advanced verify
    if (email == undefined || email == "" || password == undefined || password == "") {
      return
    }
    let data = JSON.stringify({
      "identifier": email,
      "password": password
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'api/auth/local',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    HttpClient.request(config)
      .then((response) => {
        console.log("登陆成功")
        const { jwt, user: { username, email } } = response.data;
        userStore.setUsername(username)
        userStore.setJwt(jwt)
        userStore.setEmail(email)
        window.location.replace("/")
      })
      .catch((error) => {
        console.log(error);
        alert("登陆失败")
      });
  };

  return (
    <div className="App" style={{ textAlign: "center", display: "table-cell", verticalAlign: "middle" }}>
      <div style={{ display: "inline-block" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          PCSS教育版
        </Typography>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="邮箱地址"
            name="email"
            autoComplete="email"
            autoFocus
            onInput={(e) => { setEmail((e.target as any).value) }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
            onInput={(e) => { setPassword((e.target as any).value) }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: '24px 0' }}
            onClick={onSubmit}
          >
            登录
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Login;
