import axios from "axios";
import { useUserStore } from "../_store/use-user-store";

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_API,
});

HttpClient.interceptors.request.use(
  function (config) {
    config.headers.Authorization = "Bearer " + useUserStore.getState().jwt;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
HttpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      response: { data },
    } = error;
    if (data.error.status == 401 || data.error.status == 403) {
      useUserStore.getState().clearProfile();
    }
    return Promise.reject(error);
  }
);

export { HttpClient };
