import './App.css';

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import "./index.css";
import Login from './pages/login/login';
import Home from './pages/home/home';
import InstructionDoc from './pages/instruction-docu/page';
import LaborDoc from './pages/labor-docu/page';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import PrivateRoutes from './_components/ProvideRoutes';

function App() {
  const [cookies, setCookie] = useCookies(['user'])

  function handleLogin(user: string) {
    setCookie('user', user, { path: '/' })
  }
  useEffect(() => {
    handleLogin("None")
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/*" element={<Home />} />
            <Route path="/instruction-docu" element={<InstructionDoc />} />
            <Route path="/labor-docu" element={<LaborDoc />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
