// todo store
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type UserStore = {
  jwt: string;
  username: string;
  email: string;
  setJwt: (jwt: string) => void;
  setUsername: (username: string) => void;
  setEmail: (email: string) => void;
  clearProfile: () => void;
};

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      jwt: "",
      username: "",
      email: "",
      setJwt: (jwt) => set({ jwt }),
      setUsername: (username) => set({ username }),
      setEmail: (email) => set({ email }),
      clearProfile: () => {
        set({ jwt: "" });
        set({ username: "" });
        set({ email: "" });
      },
    }),
    {
      name: "user-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    }
  )
);
