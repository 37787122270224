import { ReactNode, useEffect, useState } from "react"
import { AiFillFolder, AiOutlineFolder, AiOutlineSearch, AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai"
import { GrDocumentText } from "react-icons/gr"
import { ImWrench } from "react-icons/im"
import { TfiNewWindow } from "react-icons/tfi"
import { BsBoxArrowInDown } from "react-icons/bs"
import axios from "axios"
import { useCarStore } from "@/_store/use-car-store"
// import InstructionDoc from "@/app/instruction-docu/page"
// import LaborDoc from "@/app/labor-docu/page"
import { HttpClient } from "@/_utils/axios"
import { Link } from "react-router-dom"

function DocumentDetail({ title, notifyCount, icon, children }: { title: string, notifyCount?: string, icon?: ReactNode, children?: ReactNode }) {
    return <div className="flex-1">
        <div className="bg-[#EFF0F1] w-full h-9 flex flex-row text-sm px-3 justify-center items-center space-x-2">
            {icon}
            <p>{title}</p>
            <div className="flex-1">
                {notifyCount && <div className="w-6 h-5 rounded-full bg-red-600 text-white justify-center items-center flex">
                    {notifyCount}
                </div>}
            </div>
        </div>
        <div className="min-h-[480px] h-[480px] overflow-scroll">
            {children}
        </div>
    </div>
}

const ClassName = {
    componentButtonSelected: "bg-[#15577E] text-white px-3 py-2 border-[1px] border-[#BCBDBE] text-xs",
    componentButton: "bg-white text-black px-3 py-2 border-[1px] border-[#BCBDBE] text-xs"
}

function SelectRepairInstructionType() {
    const [repairInstructionTypes, setRepairInstructionTypes] = useState<Array<any>>()
    const carStore = useCarStore()

    const handleSelectComponent = (value: string) => {
        carStore.setRepairInstructionType(value)
        console.log(carStore.repairInstructionType)
    }

    const checkSelected = (): boolean => {
        return carStore.carModel != "" && carStore.carOrder != "" && carStore.carYear != "" && carStore.modelNr != ""
    }

    useEffect(() => {
        if (checkSelected()) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `api/porsche/repair-instruction-types?modelNr=${carStore.modelNr}`,
                headers: {}
            };

            HttpClient.request(config)
                .then((response) => {
                    setRepairInstructionTypes(() => {
                        return response.data
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [carStore])

    return (
        <div className="flex flex-row h-72 mt-6">
            <div className="w-64 overflow-hidden">
                <div className="text-[#494E51] px-2 space-x-2 flex flex-row items-center border-b-2 h-9">
                    <AiOutlineFolder size={21} />
                    <p className="font-bold text-xs">概述</p>
                </div>
                <div className="pl-6 overflow-scroll h-full">
                    {
                        repairInstructionTypes &&
                        repairInstructionTypes.map((item) => {
                            return (
                                <div key={item.hgrpCode} className="text-[#494E51] px-2 space-x-2 flex flex-row items-center border-b-2 h-9 cursor-pointer" onClick={() => { handleSelectComponent(item.hgrpCode) }}>
                                    {carStore.repairInstructionType == item.hgrpCode ? <AiOutlineFolder size={21} /> : <AiFillFolder size={21} />}
                                    <p className="font-bold text-xs">{item.hgrpTitle}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="flex-1 ml-6 text-sm border-2">
            </div>
            <div className="w-36"></div>
        </div>
    )
}

function RepairInstructionDocuList() {
    const [repairInstructionList, setRepairInstructionList] = useState<DiagnosisPageData>()
    const carStore = useCarStore()

    const getRepairInstructionFromPage = (pageNum: number) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/repair-instruction-list?modelNr=${carStore.modelNr}&pageNum=${pageNum}${carStore.repairInstructionType ? `&hgrpCode=${carStore.repairInstructionType}` : ""}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                setRepairInstructionList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getMore = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/repair-instruction-list?modelNr=${carStore.modelNr}&pageNum=${repairInstructionList!.pageNum + 1}${carStore.repairInstructionType ? `&hgrpCode=${carStore.repairInstructionType}` : ""}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                setRepairInstructionList((prev) => {
                    if (response.data.data.length > 0) {
                        if (prev?.data) {
                            const finalData = [...prev.data, ...response.data.data]
                            return {
                                ...response.data,
                                data: finalData
                            }
                        }
                    }
                    return response.data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleInstructionClick = (docuHref: string, docuTitle: string) => {
        carStore.setRepairInstructionDocuHref(docuHref)
        carStore.setRepairInstructionDocuTitle(docuTitle)
    }

    useEffect(() => {
        getRepairInstructionFromPage(1)
    }, [carStore.repairInstructionType])

    function handleLinkClick(href: string) {
        window.open(href, "_blank")
    }
    return (
        <DocumentDetail title="车间文档" icon={<GrDocumentText size={21} />}>
            {
                repairInstructionList &&
                repairInstructionList.data.map((item) => {
                    return (

                        <div className="flex flex-row px-3 py-2 border-b-2 w-full" onClick={() => handleLinkClick(`/instruction-docu?docuHref=${item.docuHref}`)}>
                            {/* <div className="flex justify-center items-center text-white bg-[#7B7E80] w-9 h-9 text-xs">
                                SIT
                            </div> */}
                            <div className="flex-1 text-sm flex items-center ml-6">
                                {item.docuTitle}
                            </div>
                            <div className="w-16 flex justify-around items-center">
                                <div className="border-2 w-6 h-6 rounded-full flex justify-center items-center cursor-pointer" onClick={() => { handleInstructionClick(item.docuHref, item.docuTitle) }}>
                                    <BsBoxArrowInDown size={15} />
                                </div>
                                {/* <Link key={item.docuTitle} to={`/instruction-docu?docuHref=${item.docuHref}`} onClick={handleLinkClick}> */}
                                <div className="border-2 w-6 h-6 rounded-full flex justify-center items-center cursor-pointer">
                                    <TfiNewWindow size={15} />
                                </div>
                                {/* </Link> */}
                            </div>
                        </div>

                    )
                })
            }
            <div className="flex-1 text-sm font-bold flex ml-6 justify-center items-center p-3 cursor-pointer" onClick={() => getMore()}>
                {"点击加载更多"}
            </div>
        </DocumentDetail>
    )
}

function LaborDocuList() {
    const [laborList, setLaborList] = useState<any>()
    const carStore = useCarStore()

    const getLaborFromPage = (pageNum: number) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/labor-list?modelNr=${carStore.modelNr}&pageNum=${pageNum}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                setLaborList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getMore = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `api/porsche/labor-list?modelNr=${carStore.modelNr}&pageNum=${laborList!.pageNum + 1}}`,
            headers: {}
        };

        HttpClient.request(config)
            .then((response) => {
                setLaborList((prev: any) => {
                    if (prev?.data) {
                        if (response.data.data.length > 0) {
                            const finalData = [...prev.data, ...response.data.data]
                            return {
                                ...response.data,
                                data: finalData
                            }
                        }
                    }
                    return response.data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleLaborClick = (laborTitle: string, contentId: string, hgrpCode: string) => {
        carStore.setLaborContentId(contentId)
        carStore.setLaborHgrpCode(hgrpCode)
        carStore.setLaborTitle(laborTitle)
    }

    useEffect(() => {
        getLaborFromPage(1)
    }, [])

    function handleLinkClick(href: string) {
        window.open(href, "_blank")
    }

    return (
        <DocumentDetail title="人工操作" icon={<GrDocumentText size={21} />}>
            {
                laborList &&
                laborList.data.map((item: any) => {
                    const laborTime = item.laborTitle.split(" ")[2]
                    const temp = item.laborTitle.split(laborTime + " ")[1]
                    const laborTitle = temp.split("(")[0]
                    const laborType = `(${temp.match(/\(([^)]+)\)/)[1]})`
                    return (
                        <div key={item.docuTitle} className="flex flex-row px-3 py-2 border-b-2 w-full" onClick={() => handleLinkClick(`/labor-docu?contentId=${item.contentId}&modelNr=${item.modelNr}&hgrpCode=${item.hgrpCode}`)}>
                            <div className="flex h-9 text-xs flex-1">
                                <div className="flex-1 space-y-2">
                                    <p>{item.laborCode}</p>
                                    <p>{laborTitle}
                                        <span className="text-gray-500">{laborType}</span>
                                    </p>
                                </div>
                                <div className="w-12 mr-6 justify-center items-center flex">
                                    {laborTime}TU
                                </div>
                            </div>
                            <div className="w-16 flex justify-around items-center">
                                <div className="border-2 w-6 h-6 rounded-full flex justify-center items-center cursor-pointer" onClick={() => { handleLaborClick(item.laborTitle, item.contentId, item.hgrpCode) }}>
                                    <BsBoxArrowInDown size={15} />
                                </div>
                                {/* <Link key={item.docuTitle} to={`/labor-docu?contentId=${item.contentId}&modelNr=${item.modelNr}&hgrpCode=${item.hgrpCode}`} > */}
                                <div className="border-2 w-6 h-6 rounded-full flex justify-center items-center cursor-pointer">
                                    <TfiNewWindow size={15} />
                                </div>
                                {/* </Link> */}
                            </div>
                        </div>
                    )
                })
            }
            <div className="flex-1 text-sm font-bold flex ml-6 justify-center items-center p-3 cursor-pointer" onClick={() => getMore()}>
                {"点击加载更多"}
            </div>
        </DocumentDetail>
    )
}

export default function CarComponentMedia() {
    const [mediaType, setMediaType] = useState<Array<string>>([
        "全部",
        "MC",
        "WM",
        "SIT",
        "SY",
        "TEQ",
        "TI",
        "L0",
    ])

    const handleSelectMediaType = (value: string) => {
        setMediaType((prev) => {
            if (prev.includes(value)) {
                const filterRes = prev.filter(item => item != value)
                return filterRes
            }
            return [...prev, value]
        })
    }

    return (
        <>
            <div className="flex flex-row items-center space-x-6">
                <div className="w-64 h-9 border-2 flex flex-row items-center ">
                    <AiOutlineSearch size={21} className="ml-2 space-x-2" />
                    <input type="text" className="flex-1 min-w-0 h-full focus:outline-none border-none bg-transparent text-sm" placeholder="查询零件" />
                    <AiOutlineClose size={21} />
                    <div className="h-full w-8 bg-[#494E51] text-white flex justify-center items-center cursor-pointer">
                        <AiOutlineArrowRight size={24} />
                    </div>
                </div>
                <p className="text-sm">概述</p>
            </div>
            <SelectRepairInstructionType />
            <div className="bg-[#EFF0F1] mt-6 p-3 text-gray-700">
                <p className="text-xs font-bold">选择零件 ID 特定媒体</p>
                <div className="mt-3 flex flex-row h-8 items-center pr-3 text-sm">
                    <div className="flex-1 flex flex-row items-center">
                        <button onClick={() => { handleSelectMediaType("全部") }} className={mediaType.includes("全部") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            全部
                        </button>
                        <button onClick={() => { handleSelectMediaType("MC") }} className={mediaType.includes("MC") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            MC
                        </button>
                        <button onClick={() => { handleSelectMediaType("WM") }} className={mediaType.includes("WM") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            WM
                        </button>
                        <button onClick={() => { handleSelectMediaType("SIT") }} className={mediaType.includes("SIT") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            SIT
                        </button>
                        <button onClick={() => { handleSelectMediaType("SY") }} className={mediaType.includes("SY") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            SY
                        </button>
                        <button onClick={() => { handleSelectMediaType("TEQ") }} className={mediaType.includes("TEQ") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            TEQ
                        </button>
                        <button onClick={() => { handleSelectMediaType("TI") }} className={mediaType.includes("TI") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            TI
                        </button>
                        <div className="h-[1px] w-3 bg-[#BCBDBE]"></div>
                        <button onClick={() => { handleSelectMediaType("L0") }} className={mediaType.includes("L0") ? ClassName.componentButtonSelected : ClassName.componentButton}>
                            L0
                        </button>
                        <button onClick={() => { handleSelectMediaType("DCL") }} className={`${mediaType.includes("DCL") ? ClassName.componentButtonSelected : ClassName.componentButton} ml-3`}>
                            DCL
                        </button>
                    </div>
                    <div>
                        <span className="text-red-600 mr-1">{">"}</span>
                        技术值
                    </div>
                </div>
            </div>
            <div className="flex flex-row space-x-3 mt-3">
                <RepairInstructionDocuList />
                <LaborDocuList />
            </div>
        </>
    )
}