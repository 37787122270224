import "./home.css"
import { useEffect, useState } from "react";
import { Navigate, redirect, Route, Routes, useNavigate } from "react-router-dom";
import Nav from "./_components/NavBar"
import CarSearch from "./car-search/car-search";
import MediaInfo from "./media-info/media-info";
import { useUserStore } from "@/_store/use-user-store";



function Home() {
    return (
        <div className="root">
            <Nav />
            <Routes>
                <Route path="/" element={<Navigate to='/car-search' />} />
                <Route path="/car-search" element={<CarSearch />} />
                <Route path="/media-info" element={<MediaInfo />} />
            </Routes>
        </div >
    )
}

export default Home