"use client"

import { IoAppsSharp, IoCarSharp } from "react-icons/io5"
import { AiFillHome, AiOutlineOrderedList, AiFillExclamationCircle, AiFillSetting } from "react-icons/ai"
import { Link, useLocation } from 'react-router-dom';
import { useUserStore } from "@/_store/use-user-store";


const NavStyleSheet: Styles = {
    root: { "display": "flex", "position": "relative", "top": "0", "width": "100%", "height": "3rem", "background": "#222529" },
    l_menu: { "display": "flex", "position": "relative", "top": "0", "width": "100%", "height": "3rem", "background": "#222529" },
    r_menu: { "display": "flex", "position": "absolute", "right": "0", "paddingLeft": "0.75rem", "paddingRight": "0.75rem", "marginLeft": "0.25rem", "flexDirection": "row", "alignItems": "center", "height": "100%", "color": "#ffffff", "width": "270px", "background": "#2A567B" },
    l_menu_nav: { "display": "flex", "height": "100%", "color": "#ffffff" },
    l_menu_home_logo: { "display": "flex", "paddingLeft": "2.25rem", "paddingRight": "2.25rem", "justifyContent": "center", "alignItems": "center", "height": "100%", "background": "#4A4E51" },
    div_link: { "display": "flex", "position": "relative", "paddingLeft": "1.25rem", "paddingRight": "1.25rem", "justifyContent": "center", "alignItems": "center", "height": "100%" },
    div_link_selected: { "display": "flex", "position": "relative", "paddingLeft": "1.25rem", "paddingRight": "1.25rem", "justifyContent": "center", "alignItems": "center", "height": "100%", "backgroundColor": "#DC2626" },
    link_item: { "display": "flex", "flexDirection": "row", "justifyContent": "center", "alignItems": "center" },
    link: { color: "white", textDecoration: "none" },
    triangle_root: { "position": "absolute", "rotate": "180deg", "bottom": "-6px" },
    triangle_left_root: { "position": "absolute", "rotate": "90deg", "left": "-9px" }
}

function Triangle() {
    return (
        <div style={NavStyleSheet.triangle_root}>
            <svg width="15" height="15" viewBox="0 0 20 20">
                <path d="M0 7 L 20 7 L 10 16" fill="white" />
            </svg>
        </div>
    )
}
function TriangleLeft({ color }: { color: string }) {
    return (
        <div style={NavStyleSheet.triangle_left_root}>
            <svg width="15" height="15" viewBox="0 0 20 20">
                <path d="M0 7 L 20 7 L 10 16" fill={color} />
            </svg>
        </div>
    )
}

export default function NavBar() {
    const pathname = useLocation();
    const userStore = useUserStore();

    const getStyle = (path: string) => {
        return getPathEqual(path) ? NavStyleSheet.div_link_selected : NavStyleSheet.div_link;
    };

    const getPathEqual = (path: string) => {
        return pathname.pathname === path
    }

    return (
        <div style={NavStyleSheet.root}>
            <div style={NavStyleSheet.l_menu}>
                <div style={NavStyleSheet.l_menu_nav}>
                    <div style={NavStyleSheet.l_menu_home_logo}>
                        <IoAppsSharp />
                    </div>
                    <div style={getStyle("/")}>
                        <Link style={NavStyleSheet.link} to="/">
                            <AiFillHome />
                        </Link>
                        {getPathEqual("/") && <Triangle />}
                        {getPathEqual("/") && <TriangleLeft color="rgb(220 38 38 / var(--tw-bg-opacity))" />}
                        {!getPathEqual("/") && <TriangleLeft color="#222529" />}
                    </div>
                    <div style={getStyle("/car-search")}>
                        <Link style={NavStyleSheet.link} to="/car-search">
                            <div style={NavStyleSheet.link_item}>
                                <IoCarSharp />
                                车辆
                            </div>
                        </Link>
                        {getPathEqual("/car-search") && <Triangle />}
                    </div>
                    <div style={getStyle("/manage")}>
                        <Link style={NavStyleSheet.link} to="/manage">
                            <div style={NavStyleSheet.link_item}>
                                <AiOutlineOrderedList />
                                作业管理
                            </div>
                        </Link>
                        {getPathEqual("/manage") && <Triangle />}
                    </div>
                    <div style={getStyle("/media-info")}>
                        <Link style={NavStyleSheet.link} to="/media-info">
                            <div style={NavStyleSheet.link_item}>
                                <AiFillExclamationCircle />
                                信息媒体
                            </div>
                        </Link>
                        {getPathEqual("/media-info") && <Triangle />}
                    </div>
                    <div style={getStyle("/cockpit")}>
                        <Link style={NavStyleSheet.link} to="/cockpit">
                            <div style={NavStyleSheet.link_item}>Communication Cockpit</div>
                        </Link>
                        {getPathEqual("/cockpit") && <Triangle />}
                    </div>
                </div>
            </div>
            <div style={NavStyleSheet.r_menu}>
                <AiFillSetting />
                <span>{userStore.username}</span>
            </div>
        </div>
    );
};